<template>
  <div>
    <TitleMore
      :title="title"
      :isMore="false"
    ></TitleMore>
    <div class="baseCard">
       <!-- <div class="itemTextContent" v-if="dataList.length === 0"
          >
           未搜索到结果
      </div> -->
      <div
        class="itemText"
        v-for="(item,index) in dataList"
        :key="index"
      >
        <p @click="$goto('/detailShow',{id:item.id})">
        <!-- id:item.id -->
          <img
            src="../../assets/img/list-little.png"
            alt=""
          >
          <span class="itemTextContent">
            {{item.title || ""}}
            </span>
        </p>
        <p class="itemTextTime"></p>
        {{item.publishDate?item.publishDate: "" | formateData}}
      </div>
      <!-- <el-pagination
        :current-page="page"
        :page-size="size"
        :total="total"
        @current-change="handleCurrentChange"
      >
      </el-pagination> -->
    </div>
  </div>
</template>

<script>
import TitleMore from "@components/TitleMore";
import TitleLine from "@components/TitleLine";
import apiUrls from "@api";
export default {
  data() {
    return {
      title: [],
      dataList: [],
      page: 0,
      size: 15,
      keyWords: "",
      total: 0,
    };
  },

  components: {
    TitleMore,
    TitleLine,
  },
  created () {
    // this.getselfDyList();
  },
  watch : {
    // keyWords (newvalue,oldvalue) {
    //     // this.$forceUpdate()
    //     // this.getselfDyList(newvalue)
    //     // this.$forceUpdate()
    // }
    $route (to, from) {
      this.getselfDyList(to.query.keyWords)
      this.$forceUpdate()
    }
  },
  mounted() {
    this.getselfDyList(this.$route.query.keyWords); 
    // let datalist = localStorage.getItem('searchdata')
    // if (datalist.length === 0) {
    //     this.dataList =JSON.parse(datalist)
    // }else {
    //     // this.dataList = [{title: "暂未搜索到结果"}]
    // }
    this.title.push(this.$route.query.name)
  },
//   watch: {
//       this.dataList :(newvalue, oldvalue) {

//       }
//   }

  methods: {
    handleCurrentChange(val) {
    //   this.page = val;
    //   this.getselfDyList(this.$route.query.keyWords);
    },
    //本会动态
    getselfDyList(type) {
     apiUrls.keyWords({searchKeyword : type, modelId: 2}).then(res=> {
          this.dataList = res.results.data;
          if (res.results.data.length ===0) {
              this.dataList = [{title: "未搜索到结果"}]
          }
       })
    },
  },
};
</script>
<style lang="less" scoped>
.selfDynamicMain {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  h3 {
    font-weight: bold;
    color: #333333;
    font-size: 20px;
    height: 30px;
    text-align: center;
    margin-top: 5px;
  }
  p {
    font-size: 16px;
    text-align: center;
    color: #ababab;
  }
}
.itemTextContent {
  display: flex;
}
.pic {
  cursor: pointer;
  display: block;
  width: 22px;
  height: 18px;
  position: relative;
  top: -4px;
  background: url('../../assets/img/pic.png') no-repeat 0 center;
  margin-left: 10px;
}
.video {
  cursor: pointer;
  display: block;
  width: 22px;
  position: relative;
  top: -4px;
  height: 18px;
  background: url('../../assets/img/video.png') no-repeat 0 center;
  margin-left: 10px;
}
.itemText{
  padding: 20px;
}
</style>